import { mapActions, mapGetters } from 'vuex';
import { nameSpace as datetimeNameSpace } from '@/vue/stores/DateTime/DateTimeStore';

const DateTimeMixin = {
  computed: {
    ...mapGetters(datetimeNameSpace, {
      currentStartDateTime: 'getStartDateTime',
      currentEndDateTime: 'getEndDateTime',
      currentDateTime: 'getDateTime',
    }),
  },

  methods: {
    ...mapActions(datetimeNameSpace, {
      $_datetime_setCurrentStartDateTime: 'setStartDateTime',
      $_datetime_setCurrentEndDateTime: 'setEndDateTime',
      setCurrentDateTime: 'setDateTime',
    }),

    dateFormat(date) {
      return date.format('YYYY-MM-DD HH:mm:ss');
    },
  },
};

export default DateTimeMixin;
