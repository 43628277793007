import get from 'lodash/get';
import pick from 'lodash/pick';
import isNil from 'lodash/isNil';
import toNumber from 'lodash/toNumber';
import every from 'lodash/every';

import { LOG_TYPE, logger } from '@emobg/web-utils';

// eslint-disable-next-line new-cap
const getCoordinates = ({ google, coordinates }) => new google.maps.LatLng(parseFloat(coordinates.lat), parseFloat(coordinates.lng));

export const calculateDistance = ({ google, firstLocation, secondLocation }) => {
  if (!every([firstLocation, secondLocation, google])) {
    const logErrorMessage = '"firstLocation", "secondLocation" and "google" argument must be provided';
    logger.message(logErrorMessage, LOG_TYPE.error);
    return null;
  }
  const distance = google.maps.geometry.spherical.computeDistanceBetween(
    getCoordinates({ google, coordinates: pick(firstLocation, ['lat', 'lng']) }),
    getCoordinates({ google, coordinates: pick(secondLocation, ['lat', 'lng']) }),
  );
  return (toNumber(distance) / 1000).toFixed(2);
};

export const distanceBetweenLocations = ({ google, firstLocation, secondLocation }) => ((
  !isNil(get(firstLocation, 'lat'))
    && !isNil(get(firstLocation, 'lng'))
    && !isNil(get(secondLocation, 'lat'))
    && !isNil(get(secondLocation, 'lng'))
)
  ? calculateDistance({ google, firstLocation, secondLocation })
  : -1);
