import invoke from 'lodash/invoke';
import { mapActions, mapGetters } from 'vuex';
import { external } from '@emobg/web-api-client';

import { nameSpace } from '@/vue/stores/Locations/LocationsStore';
import { errorNotification } from '@/handlers/errorHandler.const';

export default {

  computed: {
    ...mapGetters(nameSpace, {
      getLocationsCollection: 'getLocationsCollection',
    }),
  },

  methods: {
    ...mapActions(nameSpace, {
      setLocationCollection: 'setLocationCollection',
    }),

    async $_locations_fetchLocationsByCity(cityUUID) {
      // Try to get locations group by city
      const locations = invoke(window, 'sessionStorage.getItem', `${nameSpace}/locations`);
      // When session storage returns a truthy value we parse it. If it returns a falsy value, we create an empty object.
      const locationsByCity = locations ? JSON.parse(locations) : {};

      // Once result is pared, we have to check if there are locations or not.
      // If not, get them from Api
      if (!locationsByCity[`${cityUUID}`]) {
        try {
          // Call the Api
          const data = await external.fleetCities.getLocations(cityUUID);

          const locationsData = {
            ...locationsByCity,
            [`${cityUUID}`]: data || [],
          };

          window.sessionStorage.setItem(
            `${nameSpace}/locations`,
            JSON.stringify(locationsData),
          );
        } catch (error) {
          throw new Error(errorNotification(error));
        }
      }

      return locationsByCity[`${cityUUID}`];
    },

  },
};
