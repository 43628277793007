import { mapActions, mapGetters } from 'vuex';
import { nameSpace as activeTabNameSpace } from '@/vue/stores/ActiveTab/ActiveTabStore';

export default {
  computed: {
    ...mapGetters(activeTabNameSpace, {
      activeTabValue: 'getActiveTabValue',
    }),
  },

  methods: {
    ...mapActions(activeTabNameSpace, ['setActiveTabValue', 'resetActiveTabStore']),
  },
};
