// TODO: Temp fix, check if make sense this file: https://europcarmobility.atlassian.net/browse/CBF-882
import uniqBy from 'lodash/uniqBy';
import find from 'lodash/find';
import get from 'lodash/get';
import concat from 'lodash/concat';
import castArray from 'lodash/castArray';
import pick from 'lodash/pick';
import isNil from 'lodash/isNil';

import INPUT_TYPES from '@/constants/inputTypes';
import { compactArray } from '@emobg/web-utils';

/**
 * @typedef ChildCost
 * @property {String} uuid
 * @property {String} name
 * @property {String} code - Could be a free text or an Uuid
 */

/**
 * @typedef {Object} SelectedCost
 * @property {String} uuid
 * @property {String} name
 * @property {String} input_text
 * @property {ChildCost[]} children
 */

/**
 * @typedef {Object} CostAllocation
 * @property {String} uuid
 * @property {String} name
 * @property {String} input_text
 * @property {String} type
 * @property {Boolean} required
 * @property {String} format
 * @property {ChildCost[]} children
 */

/**
 * Get the payload to be emitted and saved in the current cost allocations selected
 * @param costAllocation
 * @param value
 * @return {SelectedCost | null}
 */
export const getEmitPayload = (costAllocation = {}, value = null) => {
  const childrenData = find(get(costAllocation, 'options'), ['value', value]);
  const commonParentData = pick(costAllocation, ['uuid', 'name', 'inputType']);
  switch (costAllocation.inputType) {
    case INPUT_TYPES.text:
      return {
        ...commonParentData,
        children: [
          {
            ...pick(costAllocation, ['uuid', 'name']),
            code: value,
          },
        ],
      };
    case INPUT_TYPES.select:
      return {
        ...commonParentData,
        children: [
          {
            uuid: get(childrenData, 'value'),
            name: get(childrenData, 'label'),
            code: get(childrenData, 'value'),
          },
        ],
      };
    default:
      return null;
  }
};

/**
 * Return a cleaned copy adding a new cost allocation
 * @param costAllocations
 * @param newCost
 * @return {Array}
 */
export const addCleanedCost = (costAllocations = [], newCost) => {
  const costAllocationsSanitize = isNil(costAllocations)
    ? []
    : castArray(costAllocations);

  const data = concat(newCost, ...costAllocationsSanitize);

  return compactArray(uniqBy(data, 'uuid'));
};

/**
 * Returns the children.0.code (the selected value) from a cost allocation
 * @param {SelectedCost} costAllocations
 * @param uuid
 * @return {String}
 */
export const getValue = (selectedCost = [], uuid) => {
  const cost = find(selectedCost, { uuid });
  return get(cost, 'children.0.code', null);
};

/**
 * Returns the validator for v-validate
 * @param {CostAllocation} costAllocation
 * @param {PhraseApp} $t
 * @return {Validator}
 */
export const getValidators = ($t, costAllocation) => {
  const requiredValidator = costAllocation.required ? {
    isRequired: {
      message: $t('refactor.company_signup.errors.mandatory'),
      whiteSpaceMessage: $t('refactor.company_signup.errors.mandatory'),
    },
  } : {};

  if (costAllocation.format) {
    return {
      ...requiredValidator,
      isPattern: {
        pattern: new RegExp(costAllocation.format),
        message: $t('common.text_input.error.pattern'),
      },
    };
  }

  return requiredValidator;
};
